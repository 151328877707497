import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

//! Dirty local storage bug solution
if (!localStorage.getItem("code")
	|| !localStorage.getItem("partner")
	|| localStorage.getItem("partner") ==="undefined")
{
	localStorage.clear();
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);


