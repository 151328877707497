export const pathologiesCervical = [
    "Esguince cervical",
    "Cefalea tensional",
    "Cervicobraquialgia",
    "Artrosis cervical",
    "Migraña",
    "Contractura muscular del trapecio",
    "Contractura muscular del angular",
    "Contractura muscular de los suboccipitales",
    "Síndrome facetario",
    "Tortícolis",
    "Síndrome del desfiladero torácico",
    "Vértigo",
    "Hernia de disco cervical",
]

export const pathologiesDorsal = [
    "Contractura muscular del infraespinoso",
    "Contractura muscular de los intercostales",
    "Contractura muscular del serrato anterior",
    "Contractura muscular del romboides",
    "Contractura muscular del trapecio inferior",
    "Contractura muscular del dorsal ancho",
    "Contractura muscular de los erectores de la columna",
]

export const pathologiesLumbar = [
    "hernia de disco lumbar",
    "Ciática",
    "Espondiloartrosis",
    "Síndrome facetario lumbar",
    "Contractura muscular del cuadrado lumbar",
    "Contractura muscular de los erectores lumbar",
    "Contractura muscular del psoas iliaco",
    "Contractura muscular del glúteo mayor",
    "Afectación sacroilíaca",
    "Espondilolisis",
    "Síndrome del piramidal",
]



