import styles from './styles/index.module.scss'
import { useEffect, useState } from 'react'
import { pathologiesCervical, pathologiesDorsal, pathologiesLumbar } from './pathologyData'

function Card({ text, check = false, error = false }) {
  return (
    <div className={`${styles.card} ${styles.fadeInUp}`} >
      {check && <img alt='check' src='/img/check.png' />}
      {error && <img alt='error' src='/img/error.png' />}
      <p>{text}</p>
    </div>
  )
}

function Login({ setIsLogged }) {

  const isCodeValid = async (code) => {
    console.log(process.env.REACT_APP_BACKEND_URL)
    let response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/physio-platform/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
      body: JSON.stringify({ code: code })
    })

    console.log(response)
    let result = await response.json();
    console.log(result)

    return (result)
  }

  const [error, setError] = useState(false)
  useEffect(async () => {
    // eslint-disable-next-line no-undef
    const code = localStorage.getItem('code')
    if ((await isCodeValid(code)).codeIsValid) {
      setIsLogged(true)
    }
  }, [])

  const handleLogin = async () => {
    // eslint-disable-next-line no-undef
    const code = Codeinput.value
    const entry = await isCodeValid(code)
    if (entry.codeIsValid) {
      // eslint-disable-next-line no-undef
		const codeLowerCased = typeof code === "string" ? code.toLowerCase() : code;
      localStorage.setItem('code', codeLowerCased)
      localStorage.setItem('partner', entry.partner);
      setIsLogged(true)
    }
    else {
      setError(true)
      setTimeout(() => setError(false), 3000)
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      // eslint-disable-next-line no-undef
      LoginButton.click()
    }
  }

  return (
    <>
      <div className={styles.loginContainer}>
        <p className={styles.title}>Ingresa tu código</p>
        <input id='Codeinput' className={styles.code} onKeyDown={handleKeyDown} placeholder='Escribe aqui...' type='text' autocomplete='off' spellcheck='false' autocorrect='off'></input>
        <button id='LoginButton' onClick={() => handleLogin()}>INGRESAR</button>
      </div>
      {error && <Card text='El código no es correcto' error={true} />}
    </>
  )
}

function Panel({ selectedPathology, setSelectedPathology }) {

  const [filter, setFilter] = useState()
  const [isSent, setIsSent] = useState(false)
  const [error, setError] = useState()

  const code = localStorage.getItem('code')
  const partner = localStorage.getItem('partner')

  const logos = {
    elma: '/img/elmaLogo.png',
    mobilite: '/img/123logo.png'
  }

  const sendEmail = async () => {
    const pathology = selectedPathology.replace(/ /g, '%20')

    const formatedLink = `https://app.fisify.com?partner=${partner}&code=${code}&pathology=${pathology}`

    console.log(formatedLink)

    let response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/physio-platform/email`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
      // eslint-disable-next-line no-undef
      body: JSON.stringify({ to: InputEmail.value, link: formatedLink, partner: partner })
    })
    return (response.json())
  }

  const handleClick = (pathology) => {
    setSelectedPathology(pathology)
  }

  const handleSubmit = async () => {
    if (selectedPathology) {
      try {
        const emailResponse = await sendEmail()
        if (emailResponse.code === 400) {
          setError('El email no es válido')
          setTimeout(() => setError(), 3000)
        }
        else {
          setIsSent(true)
          setTimeout(() => setIsSent(false), 3000)
        }
      } catch (error) {
        console.error(error);
        setError('Error de comunicación')
        setTimeout(() => setError(), 3000)
      }
    } else {
      setError('Elige una patología')
      setTimeout(() => setError(), 3000)
    }
  }

  const handleFilter = (joint) => {
    if (filter === joint) {
      setFilter()
    }
    else {
      setFilter(joint)
    }
  }

  return (
    <>
      <img className={styles.logo} src={logos[partner]} />

      <div className={styles.panel}>
        <div className={styles.header}>
          <p className={styles.title}>Selecciona una patología</p>
          <div className={styles.filterContainer}>
            <button onClick={() => handleFilter('CERVICAL')} className={filter === 'CERVICAL' && styles.active}>Cervical</button>
            <button onClick={() => handleFilter('DORSAL')} className={filter === 'DORSAL' && styles.active}>Dorsal</button>
            <button onClick={() => handleFilter('LUMBAR')} className={filter === 'LUMBAR' && styles.active}>Lumbar</button>
          </div>
        </div>
        <div className={styles.pathologyContainer}>
          <div className={styles.pathologies}>
            {filter !== 'DORSAL' && filter !== 'LUMBAR' &&
              pathologiesCervical.map(pathology => {
                return <div onClick={() => handleClick(pathology)} className={selectedPathology === pathology ? styles.activePathology : styles.pathology}>{pathology}</div>
              })
            }
            {filter !== 'CERVICAL' && filter !== 'LUMBAR' &&
              pathologiesDorsal.map(pathology => {
                return <div onClick={() => handleClick(pathology)} className={selectedPathology === pathology ? styles.activePathology : styles.pathology}>{pathology}</div>
              })
            }
            {filter !== 'CERVICAL' && filter !== 'DORSAL' &&
              pathologiesLumbar.map(pathology => {
                return <div onClick={() => handleClick(pathology)} className={selectedPathology === pathology ? styles.activePathology : styles.pathology}>{pathology}</div>
              })
            }
          </div>
        </div>
        <div className={styles.footer}>
          <div className={selectedPathology ? styles.activeSelectedPathology : styles.selectedPathology}>
            {selectedPathology ? <><img alt='check' src='/img/check.png' /><p>{selectedPathology}</p></> : <p>Selecciona una patología</p>}
          </div>
          <input id='InputEmail' placeholder='Introduce el email del paciente...'>

          </input>
          <button onClick={() => handleSubmit()}>ENVIAR</button>
        </div>
      </div>
      {error && <Card text={error} error={true} />}
      {isSent && <Card text='¡Email enviado!' check={true} />}

    </>
  )
}


function App() {

  const [isLogged, setIsLogged] = useState(false)
  const [selectedPathology, setSelectedPathology] = useState('')

  return (
    <div className={styles.appContainer}>
      <img className={styles.background} src='/img/background.webp' />
      <p className={styles.powered}>Powered by Fisify</p>
      {isLogged ?
        <Panel selectedPathology={selectedPathology} setSelectedPathology={setSelectedPathology} />
        :
        <Login setIsLogged={setIsLogged} />
      }
    </div>
  );
}

export default App;
